<template>
   <div
      class="d-flex justify-content-center align-items-center h-100"
      style="background: transparent linear-gradient(180deg, #CDDDF4 0%, #CDBAFA 100%) 0% 0% no-repeat padding-box;"
   >
      <b-form class="form-signin" @submit="signIn">
         <b-form-group>
            <b-form-input required v-model="model.login" placeholder="Enter login" />
         </b-form-group>

         <b-form-group>
            <b-form-input required type="password" v-model="model.password" placeholder="Enter password" />
         </b-form-group>

         <b-button type="submit" block variant="primary">Sign In</b-button>
      </b-form>
   </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
   name: 'auth',
   data() {
      return {
         model: {
            login: null,
            password: null,
         },
      };
   },
   computed: {
      ...mapState({
         user_roles: s => s.users.roles,
      }),
   },
   metaInfo: {
      title: 'Sign in',
   },
   methods: {
      signIn() {
         const { login, password } = this.model;

         this.$store.commit('showLoading');

         this.$store.dispatch('users/AUTH_REQUEST', { login, password }).then(() => {
            this.$store.dispatch('init');

            const prevUrl = this.$localStorage.getItem('prevRoute');
            if (prevUrl != null) {
               this.$localStorage.removeItem('prevRoute');
               window.location.href = prevUrl;
            } else if (this.user_roles.includes(38)) {
               this.$router.push({ name: 'activities' });
            } else {
               this.$router.push({ name: 'orders' });
            }
         });

         return false;
      },
   },

   mounted() {
      this.$store.commit('hideLoading');
   },

   beforeDestroy() {
      this.$store.commit('showLoading');
   },
};
</script>
